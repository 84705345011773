<template>
  <div class="content">
    <!-- <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Omni Business" subtitle="Monitoramento"/> -->
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Chat" subtitle="Monitoramento"/>
    <div class="page-content container-fluid">
      <!-- main -->
      <div class="row main">
        <div class="col-md-6 col-xl-3">
          <div class="card p-3 card-info-header">
            <div>
              <div>
                <Attendance />
              </div>
              <h3>Atendimentos</h3>
              <strong class="py-3">{{open}}</strong>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card p-3 card-info-header">
            <div>
              <div>
                <Clock />
              </div>
              <h3>Em espera</h3>
              <strong class="py-3">{{queue}}</strong>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card p-3 card-info-header">
            <div>
              <div>
                <Coffee />
              </div>
              <h3>Maior tempo de espera</h3>
              <strong class="py-3">{{maxWait | roundTime}}</strong>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card p-3 card-info-header">
            <div>
              <div>
                <Attendance />
              </div>
              <h3>Agentes Online</h3>
              <strong class="py-3">{{agentsOn}}</strong>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive card">
        <div class="card-header" style="border-bottom: 1px solid #e6e6e6;">
          <div class="w-100">
            <h3 class="card-title m-0 to-left">Monitoramento</h3>
            <div class="input-container hide">
              <input type="text" placeholder="Buscar" class="form-control">
              <img src="assets/img/icons/icon_search.png"/>
            </div>
          </div>
        </div>
        <!-- qeues -->
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col" class="th-monitor">FILAS</th>
              <th scope="col" class="th-monitor">ATIVOS</th>
              <th scope="col" class="th-monitor">TEMPO DE ESPERA</th>
              <th scope="col" class="th-monitor">TEMPO MÉDIO</th>
              <th scope="col" class="th-monitor">TEMPO MÁXIMO</th>
              <th scope="col" class="th-monitor">AGENTES DISPONÍVEIS</th>
              <th scope="col" class="th-monitor">AGENTES INDISPONÍVEIS</th>
              <th scope="col" class="th-monitor">AGENTES EM PAUSA</th>
              <th scope="col" class="th-monitor">AGENTES OFFILINE</th>
              <th scope="col" class="th-monitor">CANAIS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" class="td-monitor">
                Todos
              </th>
              <td class="td-monitor">{{open}}</td>
              <td class="td-monitor">{{queue}}</td>
              <td class="td-monitor">{{averageWait | roundTime}}</td>
              <td class="td-monitor">{{maxWait | roundTime}}</td>
              <td class="td-monitor">{{agentsOn}}</td>
              <td class="td-monitor">{{agentsUnavailable}}</td>
              <td class="td-monitor">{{agentsBreak}}</td>
              <td class="td-monitor">{{agentsOff}}</td>
              <td class="channels td-monitor"></td>
            </tr>
            <template v-for="(entry, i) in $live.administer">
              <tr :key="i" class="sub">
                <th scope="row" class="td-monitor">
                  <i class="fas fa-globe" v-if="entry.generic"></i>
                  <i class="fas fa-users" v-else></i>
                  {{entry.generic ? 'Geral' : entry.department}}
                </th>
                <td>{{entry.open_room}}</td>
                <td>{{entry.queue_size}}</td>
                <td>{{entry.average_wait | roundTime}}</td>
                <td>{{entry.maximum_wait | roundTime}}</td>
                <td>{{entry.agent_available}}</td>
                <td>{{entry.agent_unavailable}}</td>
                <td>{{entry.agent_break}}</td>
                <td>{{entry.agent_off}}</td>
                <td class="channels">
                  <template v-for="(sEntry, j) in entry.channels">
                  <b-button
                    v-b-tooltip.hover
                    :title="`${sEntry.size} atendimento(s)`"
                    :key="j">
                      <i class="fas fa-globe" v-if="sEntry.channel === 'widget'"></i>
                      <i class="fab fa-facebook-messenger" v-else-if="sEntry.channel === 'messenger'"></i>
                      <i class="fab fa-whatsapp" v-else-if="sEntry.channel === 'whatsapp' || sEntry.channel === 'whatsapp-java'"></i>
                      <i class="fab fa-google" v-else-if="sEntry.channel === 'rcs'"></i>
                      <i class="fab fa-telegram" v-else-if="sEntry.channel === 'telegram'"></i>
                      <i class="fab fa-instagram" v-else-if="sEntry.channel === 'instagram'"></i>
                      <i class="fas fa-envelope" v-else-if="sEntry.channel === 'email'"></i>
                      <GoogleBusinessIcon v-else-if="sEntry.channel === 'google-business'" />
                      <i class="fas fa-question" v-else></i>
                      <span class="sr-only">{{sEntry.channel}}</span>
                    </b-button>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import GoogleBusinessIcon from '@/components/GoogleBusinessIcon.vue';
import Attendance from '@/components/rebranding/animLottie/Attendance.vue';
import Clock from '@/components/rebranding/animLottie/Clock.vue';
import Coffee from '@/components/rebranding/animLottie/Coffee.vue';

export default {
  name: 'LiveDashboard',
  components: {
    PageHeader,
    GoogleBusinessIcon,
    Attendance,
    Clock,
    Coffee,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/live/reports/stat',
        2: '/live/dashboard',
      },

      optionsPlayer: {
        minimizable: false,
        playerSize: 'standard',
        backgroundColor: '#fff',
        backgroundStyle: 'color',
        theme: {
          controlsView: 'standard',
          active: 'light',
          light: {
            color: '#3D4852',
            backgroundColor: '#fff',
            opacity: '0.7',
          },
          dark: {
            color: '#fff',
            backgroundColor: '#202020',
            opacity: '0.7',
          },
        },
      },
    };
  },
  computed: {
    open() {
      let sum = 0;

      this.$live.administer.forEach((entry) => {
        sum += entry.open_room;
      });

      return sum;
    },
    queue() {
      let sum = 0;

      this.$live.administer.forEach((entry) => {
        sum += entry.queue_size;
      });

      return sum;
    },
    averageWait() {
      let check = 0;

      this.$live.administer.forEach((entry) => {
        if (entry.average_wait > check) check = entry.average_wait;
      });

      return check;
    },
    maxWait() {
      let check = 0;

      this.$live.administer.forEach((entry) => {
        if (entry.maximum_wait > check) check = entry.maximum_wait;
      });

      return check;
    },
    agentsOn() {
      return this.$live.administer?.[0]?.['agent_available'] === undefined ? 0 : this.$live.administer?.[0]?.['agent_available'];
    },
    agentsUnavailable() {
      return this.$live.administer?.[0]?.['agent_unavailable'];
    },
    agentsBreak() {
      return this.$live.administer?.[0]?.['agent_break'];
    },
    agentsOff() {
      return this.$live.administer?.[0]?.['agent_off'];
    },
    agentsTotal() {
      return this.agentsOn + this.agentsUnavailable + this.agentsBreak + this.agentsOff;
    },
  },
  filters: {
    roundTime: (value) => {
      const days = Math.floor(value / 1440);

      // console.log('roundTime.days', days);

      if (days >= 1) return `${days} dia${days < 2 ? '' : 's'}`;

      const hours = Math.floor(value / 60);

      // console.log('roundTime.hours', hours);

      if (hours >= 1) return `${hours} hora${hours < 2 ? '' : 's'}`;

      // console.log('roundTime.mins', value);

      return `${Math.floor(value)} min${value < 2 ? '' : 's'}`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main .card {
  position: relative;
  height: 150px;
  overflow: hidden;

  h3 {
    font-size: 90%;
    font-weight: bold;
  }

  & > div {
    position: relative;
    z-index: 5;
  }

  .bg {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 4;
    opacity: .15;
    font-size: 64pt;
  }

  & > div > strong {
    display: block;
    font-size: 200%;
  }
  .actives {
    * {
      font-size: 92%;
    }
    .status {
      vertical-align: middle;
      display: inline-block;
      border-radius: 8px;
      width: 12px;
      height: 12px;
    }
  }
  .progress {
    button::v-deep {
      border-radius: 0;
      border: none;
      padding: 0;
      margin: 0;
      height: auto !important;
    }
  }
}

table {
  min-width: 600px;

  th {
    white-space: nowrap;
  }

  tr > :first-child i {
    text-align: center;
    width: 20px;
  }

  tr.sub > :first-child i {
    margin-left: .4rem;
  }

  .channels {
    button::v-deep {
      background: transparent !important;
      height: 24px !important;
      width: 24px !important;
      min-width: 1px;
      padding: 0;
      margin-right: 2px;

      &::last-child {
        margin-right: 0;
      }
    }
  }

  th[scope=col]:not(:first-child), td {
    text-align: center;
  }

  th[scope=row] {
    text-transform: capitalize;
  }
}
.svg_wrapper {
  margin: 0 auto !important;
  width: 70px !important;
  height: auto !important;
  margin-top: 15px !important;
}
.card-info-header  {
  text-align: center;
}
.card-info-header h3 {
  color: var(--gray-font-color);
  margin: 0;
}
.card-info-header {
  height: 200px !important;
}
.card-info-header strong{
  color: #9CA7B0;
  font-weight: 100 !important;
}
.th-monitor {
  color: var(--gray-font-color);
  font-size: 14px !important;
  border: #fff 1px solid !important;
  padding-top: 30px;
  padding-bottom: 30px;
  background: var(--background-3);
  border-color: var(--table-border) !important;
}
.td-monitor {
  border: 1px solid #fff !important;
  border-color: var(--table-border) !important;
  font-weight: 300;
  color: var(--gray-font-color-2);
}
.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: var(--background-2);
}
.card-title {
  font-size: 26px !important;
  color: var(--foreground);
}
.m-0 {
  margin: 0px !important;
}
.w-100 {
  width: 100% !important;
}
.to-left {
  float: left !important;
}
.input-container {
  float: right;
  position: relative;
  width: 40%;
}
.input-container img {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  margin-top: 10px;
  margin-right: 8px;
  cursor: pointer;
}
</style>
